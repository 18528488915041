<template>
    <div>
      <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
        <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
        <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
     <div class="pa-5"> <h2 class="text--grey">Upload Question Paper</h2></div>
      <v-row justify="end">
     <v-btn class="primary mx-5 my-4 pa-2" @click="opendialog()">
            ADD NEW Question Paper
        </v-btn>
        </v-row>
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          
          max-width="600px"
        >
          
          <v-card>
            <v-card-title>
              <span class="text-h5">  Upload Question Paper</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                    <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      :items="academic_year_list"
                      label="Academic Year*"
                      required
                      v-model="selected_ay"
                      item-text="ay"
                      item-value="id"
                    ></v-select>
                  </v-col>
                  <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    :items="semester_list"
                    label="Sem*"
                    required
                    v-model="selected_sem"
                    item-text="sem"
                      item-value="id"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    :items="year_list"
                    label="Year*"
                    required
                    v-model="selected_year"
                    item-text="year"
                      item-value="id"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-autocomplete
                    :items="dept_list"
                    label="Deparment*"
                    required
                    v-model="selected_dept"
                    item-text="name"
                      item-value="id"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-autocomplete
                    :items="course_list"
                    label="course*"
                    required
                    v-model="selected_course"
                    item-text="name"
                      item-value="id"
                  ></v-autocomplete>
                </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  
                  >
                    <v-text-field
                      label="Question Paper Name*"
                      required
                    v-model="question_paper_name"
                    ></v-text-field>
                  </v-col>
                  <v-col
                  cols="12"
                  sm="6"
                
                >
                  <v-file-input
                    label="Select File*"
                    required
                    v-model="selected_file"
                  ></v-file-input>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                  
                  >
                    <v-text-field
                      label="Description*"
                      required
                    v-model="desc"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save_question_paper()"
                :loading="loading_new"
              >
                UPLOAD
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <!-- Add question paper end here -->

      
        <v-card class="mx-2 my-4 pa-2">
 <v-row class="pt-4">
   
                    <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-select rounded outlined
                      :items="academic_year_list_filter"
                      label="Academic Year"
                      required
                      v-model="selected_ay_filter"
                      item-text="ay"
                      item-value="id"
                    ></v-select>
                  </v-col>
                  <v-col
                  cols="12"
                  sm="2"
                >
                  <v-select rounded outlined
                    :items="semester_list_filter"
                    label="Sem"
                    required
                    v-model="selected_sem_filter"
                    item-text="sem"
                      item-value="id"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                >
                  <v-select rounded outlined
                    :items="year_list_filter"
                    label="Year"
                    required
                    v-model="selected_year_filter"
                    item-text="year"
                      item-value="id"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-autocomplete rounded outlined
                    :items="dept_list_filter"
                    label="Deparment"
                    required
                    v-model="selected_dept_filter"
                    item-text="name"
                      item-value="id"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-autocomplete rounded outlined
                    :items="course_list_filter"
                    label="course"
                    required
                    v-model="selected_course_filter"
                    item-text="name"
                      item-value="id"
                  ></v-autocomplete>
                </v-col>
 </v-row>
 
 <center><hr style="width:70%;"/></center>

                <v-row justify="center" >
                  <v-col
                    cols="12"
                    sm="3"
                  
                  >
                    <v-autocomplete solo clearable
                      label="Question Paper Name"
                      required
                      item-text="questionpapername"
                      item-value="questionpapername"
                    v-model="question_paper_name_filter"
                    @input.native="search_question_paper($event.srcElement.value)"
                   :items="question_paper_list"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row justify="center" >
                  <v-col
                  cols="12"
                  sm="3"
                
                >
                <center >
                <v-btn dark class="green mt-3" @click="get_question_paper()">
                  FETCH QUESTION PAPER
                  </v-btn>
                </center>
                </v-col>

                  </v-row>

 
        </v-card>
        <v-skeleton-loader v-if="loading_fetch"
        v-bind="attrs"
        type="article"
      ></v-skeleton-loader>
        <v-data-table
        v-if="Array.isArray(table_data) && table_data.length"
        :headers="headers"
        :items="table_data"
        :items-per-page="5"
        class="elevation-1"
    >
     <template v-slot:top>
      <v-toolbar
        flat
      >
      <v-dialog
      v-model="editdialog"
      
      max-width="600px"
    >
      
      <v-card>
        <v-card-title>
          <span class="text-h5">  Edit Question Paper</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
                <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  :items="academic_year_list"
                  label="Academic Year*"
                  required
                  v-model="editedItem.ay_id"
                  item-text="ay"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col
              cols="12"
              sm="6"
            >
              <v-select
                :items="semester_list"
                label="Sem*"
                required
                v-model="editedItem.sem_id"
                item-text="sem"
                  item-value="id"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                :items="year_list"
                label="Year*"
                required
                v-model="editedItem.year_id"
                item-text="year"
                  item-value="id"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                :items="dept_list"
                label="Deparment*"
                required
                v-model="editedItem.program_id"
                item-text="name"
                  item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                :items="course_list"
                label="course*"
                required
                v-model="editedItem.course_id"
                item-text="name"
                  item-value="id"
              ></v-autocomplete>
            </v-col>
              <v-col
                cols="12"
                sm="6"
              
              >
                <v-text-field
                  label="Question Paper Name*"
                  required
                v-model="editedItem.paper_name"
                ></v-text-field>
              </v-col>
              <v-col
              cols="12"
              sm="6"
            
            >
              <v-file-input
                label="Select File*"
                required
                v-model="selected_file"
              ></v-file-input>
            </v-col>
            <v-col
                cols="12"
                sm="6"
              
              >
                <v-text-field
                  label="Description*"
                  required
                v-model="editedItem.desc"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="editdialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="edit_question_paper()"
            :loading="loading_edit"
          >
            EDIT
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
   
         </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
         mdi mdi-pencil-box
        </v-icon>
        <v-icon class="red--text" @click="deleteQuestionPaper(item)"> mdi mdi-delete</v-icon>

    </template>
    <template v-slot:item.download="{ item }">
    <a :href="item.download"  target="_blank"> <v-icon class="blue--text" >mdi-cloud-download</v-icon></a>
  </template>
  </v-data-table>
      
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import moment from "moment";
  import "@/assets/css/color.css"; // css needs to be imported for each dashboard
  export default {
    data: () => ({
        snackbar:false,
        color:"",
        snackbar_msg:"",
        dialog:false,
        academic_year_list:[],
        semester_list:[],
        year_list:[],
        dept_list:[],
        course_list:[],
        selected_ay:"",
        selected_sem:"",
        selected_year:"",
        selected_dept:"",
        selected_course:"",
        question_paper_name:"",
        selected_file:null,
        desc:"",

        academic_year_list_filter:[],
        semester_list_filter:[],
        year_list_filter:[],
        dept_list_filter:[],
        course_list_filter:[],
        selected_ay_filter:"ALL",
        selected_sem_filter:"ALL",
        selected_year_filter:"ALL",
        selected_dept_filter:"ALL",
        selected_course_filter:"ALL",
        question_paper_name_filter:"",
        question_paper_list:[],
        table_data:[],
        loading_edit:false,
        loading_new:false,
        loading_fetch:false,
        headers: [
          { text: 'Action', value: 'actions' },
          { text: 'Academic Year', value: 'ay' },
          { text: 'Name', value: 'paper_name' },
          { text: 'Course', value: 'course' },
          
          { text: 'Department', value: 'program' },
          { text: 'Year', value: 'year' },
          { text: 'Semester', value: 'sem' },
          { text: 'Download', value: 'download' },
       
        ],
        editdialog:false,
        editedItem:{},
    }),
   
    mounted() {
      this.onLoad();
    },
      methods: {

        deleteQuestionPaper(item)
        {
             var params={"id":item.id}

        axios
          .post("/QuestionPaper/deleteQuestionPaper",params)
          .then((res) => {
           
            if (res.data.msg == "200") {
            
       this.showSnackbar("green","Delete successfully.");
       this.get_question_paper();
            
      }else{
        this.showSnackbar("green",res.data.msg);
       
      }
            });
        },

        edit_question_paper()
        {
        
            if(this.editedItem.ay_id==""){
            this.showSnackbar("red","Please Select Academic Year");
            return
            }
            if(this.editedItem.sem_id==""){
            this.showSnackbar("red","Please Select Semester");
            return
            }
            if(this.editedItem.year_id==""){
            this.showSnackbar("red","Please Select Year");
            return
            }
            if(this.editedItem.program_id==""){
            this.showSnackbar("red","Please Select Department");
            return
            }
            if(this.editedItem.course_id==""){
            this.showSnackbar("red","Please Select Course");
            return
            }
            if(this.editedItem.paper_name==""){
            this.showSnackbar("red","Please Enter Question Paper Name");
            return
            }
           
           

               this.loading_new = true;
               let formData = new FormData();
                formData.append("ay", this.editedItem.ay_id);
                formData.append("sem", this.editedItem.sem_id);
                formData.append("year", this.editedItem.year_id);
                formData.append("dept", this.editedItem.program_id);
                formData.append("course", this.editedItem.course_id);
                formData.append("qp_name", this.editedItem.paper_name);
                formData.append("id", this.editedItem.id);
                formData.append("file", this.selected_file);
                formData.append("desc", this.editedItem.desc);

        axios
          .post("/QuestionPaper/edit_question_paper",formData)
          .then((res) => {
           
            if (res.data.msg == "200") {
             this.loading_edit=false;
       this.editdialog=false;
       this.showSnackbar("green","Edit successfully.");
       this.get_question_paper();
            
      }else{
        this.showSnackbar("green",res.data.msg);
        this.loading_edit=false;
      }
            });
        },


          editItem(item) {
     
     
      this.editedItem = Object.assign({}, item);
      this.editdialog = true;
     console.log(this.editedItem);
    },
        search_question_paper(value) {
   

      if (value != "") {
        var params = { text: value};
        axios
          .post("/QuestionPaper/search_question_paper", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.question_paper_list = res.data.data;
            }
          })
         
      }
    },
        get_question_paper() {
          this.table_data=[];
         if(this.question_paper_name_filter==null)
         this.question_paper_name_filter="";
        var params={
          "ay":this.selected_ay_filter,
          "sem":this.selected_sem_filter,
          "year":this.selected_year_filter,
          "program":this.selected_dept_filter,
          "course":this.selected_course_filter,
          "paper_name":this.question_paper_name_filter
        };
this.loading_fetch=true;
          axios
            .post("/QuestionPaper/get_question_paper",params)
            .then((res) => {
              if (res.data.msg == "200") {
            this.table_data=res.data.question_paper_data;
            this.loading_fetch=false;
               }
              else{
                this.showSnackbar("red",res.data.msg);
                this.loading_fetch=false;
              }
              });
          
        },


        
        save_question_paper()
        {
        
            if(this.selected_ay==""){
            this.showSnackbar("red","Please Select Academic Year");
            return
            }
            if(this.selected_sem==""){
            this.showSnackbar("red","Please Select Semester");
            return
            }
            if(this.selected_year==""){
            this.showSnackbar("red","Please Select Year");
            return
            }
            if(this.selected_dept==""){
            this.showSnackbar("red","Please Select Department");
            return
            }
            if(this.selected_course==""){
            this.showSnackbar("red","Please Select Course");
            return
            }
            if(this.question_paper_name==""){
            this.showSnackbar("red","Please Enter Question Paper Name");
            return
            }
            if(this.selected_file==null){
            this.showSnackbar("red","Please Select File");
            return
            }
           

               this.loading_new = true;

               let formData = new FormData();
                formData.append("ay", this.selected_ay);
                formData.append("sem", this.selected_sem);
                formData.append("year", this.selected_year);
                formData.append("dept", this.selected_dept);
                formData.append("course", this.selected_course);
                formData.append("qp_name", this.question_paper_name);
                formData.append("file", this.selected_file);
                formData.append("desc", this.desc);

        axios
          .post("/QuestionPaper/save_question_paper",formData)
          .then((res) => {
            if (res.data.msg == "200") {
              this.loading_new=false;
       this.dialog=false;
       this.showSnackbar("green","upload successfully.");
             }else{
              this.dialog=false;
              this.showSnackbar("green",res.data.msg);
             }
            });
        },
      onLoad() {
          
        this.overlay = true;
        axios
          .post("/QuestionPaper/upload_question_paper_init")
          .then((res) => {
            if (res.data.msg == "200") {
                this.academic_year_list=res.data.academic_year_list;
        this.semester_list=res.data.semester_list;
        this.year_list=res.data.year_list;
        this.dept_list=res.data.dept_list;
        this.course_list=res.data.course_list;

        this.academic_year_list_filter=res.data.academic_year_list;
        this.semester_list_filter=res.data.semester_list;
        this.year_list_filter=res.data.year_list;
        this.dept_list_filter=res.data.dept_list;
        this.course_list_filter=res.data.course_list;

        this.academic_year_list_filter.push({id:"ALL",ay:"ALL"});
        this.semester_list_filter.push({id:"ALL",sem:"ALL"});
        this.year_list_filter.push({id:"ALL",year:"ALL"});
        this.dept_list_filter.push({id:"ALL",name:"ALL"});
        this.course_list_filter.push({id:"ALL",name:"ALL"});
       
             }});
        
      },
      opendialog()
      {
            this.selected_ay="";
            this.selected_sem="";
            this.selected_year="";
            this.selected_dept="";
                this.selected_course="";
                this.question_paper_name="";
                this.selected_file=null;
                this.dialog=true;
      },
   
      showSnackbar(clr, msg) {
        this.snackbar = true;
        this.color = clr;
        this.snackbar_msg = msg;
      },
 
     
  
    },
  };
  </script>
  
  